    // Imports
    // ------
    import React from 'react';
    
    // Styles
    // ------
  
    
    // Component
    // ------
    const Error404 = () => (
        <p>Error</p>
    );
    
    export default Error404;
    